import { useCloudApi } from '@/composables/cloudApi'
import { createInstance, deleteInstance, fetchInstances } from '@/api/instances'
import { useAnalytics } from '@/composables/analytics'
import { i18n } from '@/lang'
import { useI18n } from 'vue-i18n'
import type {
  V1alpha1PoolOption,
  V1alpha1PulsarInstance
} from '@streamnative/cloud-api-client-typescript'
import type { CloudV1alpha1PoolRef } from '@streamnative/cloud-api-client-typescript'
import type { PulsarState } from './usePulsarState'
import { view, PulsarInstance, PoolOption, useRbac } from './useRbac'
import { capitalize } from 'lodash-es'
import dayjs from 'dayjs'

let lastOrg: string | undefined = undefined
const { t } = i18n.global
const api = useCloudApi()
const { findPool } = usePools()
const instances = ref<Array<V1alpha1PulsarInstance>>([])
const poolOptions = ref<Array<V1alpha1PoolOption>>([])
const activeInstance = computed(() => {
  const { instance } = usePulsarState()
  if (instance.value === undefined || instance.value === '') {
    return {}
  }

  const foundInstance = instances.value.find(i => i.metadata?.name === instance.value)

  if (foundInstance) {
    return foundInstance
  }
  // TODO need to add this back in when adding in last viewed org/ins
  // if (!foundInstance && instance.value) {
  //   // instance is set but not found
  //   clearLastViewedOrgIns()
  // }
  return {}
})
const instanceLocations = computed(() => {
  const instanceObj = activeInstance.value
  const poolRefName = instanceObj.spec?.poolRef?.name
  const poolRefNamespace = instanceObj.spec?.poolRef?.namespace
  if (!poolRefName || !poolRefNamespace) {
    return []
  }
  const locations = new Set<string>()
  const poolRefNameToMatchWith = `${poolRefNamespace}-${poolRefName}`

  poolOptions.value
    .filter(poolOption => poolOption.metadata?.name === poolRefNameToMatchWith)
    .forEach(poolOption => {
      poolOption.spec?.locations.forEach(poolOptionLocation => {
        if (poolOptionLocation.location) {
          locations.add(poolOptionLocation.location)
        }
      })
    })

  return Array.from(locations)
})

const activeInstanceType = computed(() => {
  return activeInstanceTypeFunc(activeInstance.value)
})

const activeInstanceTypeFunc = (ins?: V1alpha1PulsarInstance) => {
  return ins?.spec?.type
}

const deploymentType = computed(() => {
  return getDeploymentType(activeInstance.value)
})

const deploymentTypeLabel = computed(() => {
  return getDeploymentTypeLabel(activeInstance.value)
})

const getDeploymentType = (instance: V1alpha1PulsarInstance) => {
  return instance.spec?.poolRef?.namespace === 'streamnative' ? 'hosted' : 'managed'
}

const getDeploymentTypeLabel = (instance: V1alpha1PulsarInstance) => {
  return getDeploymentType(instance) === 'managed'
    ? 'managed'
    : capitalize(getDeploymentType(instance))
}

const instanceScopes = ref<string>()

export const INSTANCE_DETAILS: Record<string, Record<string, Record<string, string>>> = {
  regions: {
    GCP: {
      value: 'GCP',
      label: 'Google Cloud',
      cloudProvider: 'gcloud',
      icon: 'iconfont icon-google_cloud-icon'
    },
    AWS: {
      value: 'AWS',
      label: 'AWS',
      cloudProvider: 'aws',
      icon: 'iconfont icon-aws'
    },
    AZURE: {
      value: 'AZURE',
      label: 'AZURE',
      cloudProvider: 'azure',
      icon: 'iconfont icon-azure'
    }
  },
  plans: {
    FREE: {
      name: 'instance.free',
      value: 'FREE'
    },
    STANDARD: {
      name: 'instance.standard',
      value: 'STANDARD'
    },
    SERVERLESS: {
      name: 'instance.serverless',
      value: 'SERVERLESS'
    },
    DEDICATED: {
      name: 'instance.dedicated',
      value: 'DEDICATED'
    },
    BYOC: {
      name: 'instance.byoc',
      value: 'BYOC'
    },
    BYOCPRO: {
      name: 'instance.byocPro',
      value: 'BYOC-PRO'
    }
  },
  zonals: {
    zonal: {
      value: 'zonal',
      label: 'instance.saz',
      azValue: 'SAZ'
    },
    regional: {
      value: 'regional',
      label: 'instance.maz',
      azValue: 'MAZ'
    }
  }
} as const

const timeOptions = ref([
  '12:00 AM',
  '1:00 AM',
  '2:00 AM',
  '3:00 AM',
  '4:00 AM',
  '5:00 AM',
  '6:00 AM',
  '7:00 AM',
  '8:00 AM',
  '9:00 AM',
  '10:00 AM',
  '11:00 AM',
  '12:00 PM',
  '1:00 PM',
  '2:00 PM',
  '3:00 PM',
  '4:00 PM',
  '5:00 PM',
  '6:00 PM',
  '7:00 PM',
  '8:00 PM',
  '9:00 PM',
  '10:00 PM',
  '11:00 PM'
])

const durationOptions = ref(['4h', '6h', '8h', '12h', '24h'])

const setInstances = (_instances: Array<V1alpha1PulsarInstance>) => {
  instances.value = _instances.sort((a, b) => {
    if (!a.metadata?.name || !b.metadata?.name) return 0
    if (a.metadata.name < b.metadata.name) {
      return -1
    }
    if (a.metadata.name > b.metadata.name) {
      return 1
    }
    return 0
  })
}

const getPoolOptions = async ({ organization }: { organization: string }) => {
  const options = await api.listNamespacedPoolOption(organization)
  poolOptions.value = options.data.items
}

export const removeInstance = async (organization: string, instance: string) => {
  const { getClusterMap, clusterMap } = useCluster()

  await getClusterMap({ organization: organization })
  if ((clusterMap.value[instance] ?? []).filter(clus => !clus.generated.deleted).length > 0) {
    throw Error(t('instance.unableToDeleteActiveCluster'))
  }

  const data = await deleteInstance({ organization, instanceName: instance })

  useAnalytics().identifyUser()

  if (!data || data.status === 'Failure') {
    throw Error(t('instance.errorDelete'))
  }
  await getInstances({ organization })
  const _instance = instances.value.find(i => i?.metadata?.name !== instance)
  if (_instance && _instance.metadata && !_instance.metadata.deletionTimestamp) {
    _instance.metadata.deletionTimestamp = dayjs().format('YYYY-MM-DDTHH:mm:ssZ')
  }
}

export const addInstance = async (payload: {
  instance: string
  availabilityMode: 'zonal' | 'regional'
  type: 'FREE' | 'STANDARD' | 'SERVERLESS' | 'DEDICATED' | 'BYOC' | 'BYOC-PRO' | undefined
  poolRef?: CloudV1alpha1PoolRef
  organization: string
  isURSAFeaturePossible?: boolean
  engine?: 'ursa' | 'classic'
}) => {
  if (!payload.instance) {
    throw t('instance.inputInstanceNameNotification')
  }
  if (!payload.poolRef) {
    throw t('instance.inputPoolNotification')
  }
  const newInstance = await createInstance(payload)

  useAnalytics().identifyUser()

  setInstances([...instances.value, newInstance])
}

export const getInstances = async ({ organization }: { organization: string }) => {
  const res = await fetchInstances(organization)
  setInstances(res.items)
  // track instance count change
  useAnalytics().identifyUser()
}

export const istioEnabled = computed(() => {
  const annotations = activeInstance.value?.metadata?.annotations
  return (
    !!annotations && annotations?.['annotations.cloud.streamnative.io/istio-enabled'] === 'true'
  )
})

export const isIstioEnabledForInstance = (instance: V1alpha1PulsarInstance) => {
  const annotations = instance.metadata?.annotations
  return (
    !!annotations && annotations?.['annotations.cloud.streamnative.io/istio-enabled'] === 'true'
  )
}

export const isPaidInstance = computed(() => {
  return activeInstance.value?.spec?.type !== 'free'
})

export const getTranslatedInstanceDetails = (instance: V1alpha1PulsarInstance) => {
  if (!instance?.spec) {
    // It's possible that `translatedInstanceDetails()` getter is called before
    // activeInstance has been set.
    // Just return empty object here as it will get updated when instance is set.
    return {}
  }
  const { availabilityMode, type, poolRef } = instance.spec
  const name = instance.metadata?.name
  const { regions, plans, zonals } = INSTANCE_DETAILS

  if (!poolRef) {
    return {}
  }
  const pool = findPool(poolRef)
  let planName = undefined
  if (pool?.cloudType === 'gcloud') {
    planName = 'GCP'
  } else if (pool?.cloudType === 'aws') {
    planName = 'AWS'
  } else if (pool?.cloudType === 'azure') {
    planName = 'AZURE'
  }

  return {
    name,
    availabilityMode: t(zonals[availabilityMode].label),
    // TODO none of these should be undefined, it causes render errors
    type: type ? plans[type.replace('-', '').toUpperCase()].name : undefined,
    plan: planName ? regions[planName].label : undefined,
    cloudProvider: planName ? regions[planName].cloudProvider : undefined,
    deploymentType: pool?.deploymentType,
    deploymentTypeLabel: pool?.deploymentTypeLabel
  }
}

export const translatedInstanceDetails = computed(() => {
  return getTranslatedInstanceDetails(activeInstance.value)
})

// TODO maybe replace?
export const audience = computed(() => {
  const { organization, instance } = usePulsarState()
  if (!instance.value || !activeInstance.value?.status) {
    return undefined
  }
  return (
    activeInstance.value.status?.auth?.oauth2?.audience ??
    `urn:sn:pulsar:${organization.value}:${instance.value}`
  )
})

export const isInstanceDeletedFn = (instance: V1alpha1PulsarInstance) => {
  return !!instance?.metadata?.deletionTimestamp
}

export const isInstanceDeletedByNameFn = (name: string) => {
  const instance = instances.value.find(i => i.metadata?.name === name)
  if (!instance) {
    return false
  }
  return isInstanceDeletedFn(instance)
}

export const isInstanceReadyByNameFn = (name: string) => {
  const instance = instances.value.find(i => i.metadata?.name === name)
  if (!instance) {
    return false
  }
  return isInstanceReadyFn(instance)
}

export const isInstanceReadyFn = (instance: V1alpha1PulsarInstance) => {
  const condition = instance?.status?.conditions?.find(c => c.type === 'Ready')
  return condition ? condition.status === 'True' : false
}

export const instanceReady = computed(() => {
  return isInstanceReadyFn(activeInstance.value)
})

export const instanceNames = computed(() => {
  return instances.value.map(instance => instance.metadata?.name ?? '')
})

export const hasInstances = computed(() => {
  return instances.value.length > 0
})

// locally scoped composable for pinging a cluster
// export const usePingInstance = (params: { organization?: string; instance: string }) => {
//   const { mustOrganization } = usePulsarState()
//   const instance = ref<V1alpha1PulsarInstance>()
//   const conditions = computed(() => instance.value?.status?.conditions ?? [])
//   const error = ref('')
//   const lock = new AsyncLock()
//   const { resume, pause, isActive } = useIntervalFn(async () => {
//     if (!lock.isBusy(params.instance)) {
//       await lock.acquire(params.instance, async () => {
//         try {
//           const { data } = await useCloudApi().readNamespacedPulsarInstance(
//             params.instance,
//             params.organization ?? mustOrganization()
//           )

//           instance.value = data
//         } catch (e) {
//           error.value = getErrorMessage(e)
//           pause()
//         }
//       })
//     }
//   }, 5000)
//
//   return {
//     instance,
//     conditions,
//     resume,
//     pause,
//     isActive,
//     error
//   }
// }

export const init = (
  initialState: PulsarState,
  can?: ((action: string, type: any, conditions?: any) => boolean) | true
) => {
  const { organization, instance } = usePulsarState()
  const { abilityUpdating } = useRbac()

  const valueChanged = async ([org, ins, ab]: [
    string | undefined,
    string | undefined,
    boolean | undefined
  ]) => {
    if (!org) {
      instances.value = []
      poolOptions.value = []
      lastOrg = undefined
      return
    }
    if (ab) {
      return
    }

    const isOrgChanged = org !== lastOrg
    const isInstanceMissing = ins ? !instances.value.find(i => i.metadata?.name === ins) : false

    if (isOrgChanged || isInstanceMissing || !ab) {
      if (can && (can === true || can(view, PulsarInstance))) {
        await getInstances({ organization: org })
      }
      if (can && (can === true || can(view, PoolOption))) {
        await getPoolOptions({ organization: org })
      }
    }
    lastOrg = org
  }

  watch([organization, instance, abilityUpdating], valueChanged)
  return valueChanged([initialState.organization, initialState.instance, abilityUpdating.value])
}

// alias to match cluster computed of similar name
const isActiveInstanceReady = computed(() => {
  return instanceReady.value
})

const isActiveInstanceServerless = computed(() => {
  return activeInstance.value?.spec?.type === 'serverless'
})

const getCloudProviderByName = (name: string) => {
  const instance = instances.value.find(i => i.metadata?.name === name)
  if (!instance) {
    return 'Unknown'
  }
  const pool = usePools().findPool(instance?.spec?.poolRef)
  if (pool?.cloudType === 'gcloud') {
    return 'Google Cloud'
  } else if (pool?.cloudType === 'aws') {
    return 'AWS'
  } else if (pool?.cloudType === 'azure') {
    return 'AZURE'
  }
  return 'Unknown'
}

const getInstanceStatusByName = (instance: string, clusters: any[]) => {
  const _instance = instances.value.find(item => item.metadata?.name === instance)
  if (!_instance) {
    return 'unknown'
  }
  return getInstanceStatus(_instance, clusters)
}

const getInstanceStatus = (instance: V1alpha1PulsarInstance, clusters: any[]) => {
  const condition = instance.status?.conditions?.find(c => c.type === 'Ready')
  const isInstanceReady = condition ? condition.status === 'True' : false
  const isClusterAvailable =
    clusters && clusters.length > 0 && !!clusters.every(c => c?.generated.conditions?.Available)
  const isClusterDeleted =
    clusters && clusters.length > 0 && !!clusters.find(c => c.generated.deleted)

  if (isInstanceReady && isClusterAvailable) {
    // instance and all clusters are ready
    return 'ready'
  } else if (!isInstanceReady) {
    if (clusters && clusters.length > 0) {
      // instance is not ready while having clusters.  This means once working previous state
      // became invalid either due to payment becoming invalid or etc.  For now return deploying
      // but it maybe something we want to improve on later
      return 'deploying'
    } else {
      // instance is not ready without any clusters, it is simply not ready
      return 'not ready'
    }
  } else if (clusters && clusters.length > 0 && isClusterDeleted) {
    // iregardless of the instance state, it has non 0 cluster, but cluster is deleting
    return 'deleting'
  } else if (clusters && clusters.length > 0 && !isClusterAvailable) {
    // iregardless of the instance state, has non 0 cluster, but cluster is not ready
    return 'deploying'
  } else {
    // instance has no clusters
    return 'no cluster'
  }
}

const getStatusLabel = (name: string, clusters: any[]) => {
  const status = getInstanceStatusByName(name, clusters).toLowerCase()
  return capitalize(status)
}

const getStatusColor = (name: string, clusters: any[]) => {
  const status = getInstanceStatusByName(name, clusters).toLowerCase()
  if (status === 'ready') {
    return 'positive'
  } else if (status === 'deploying') {
    return 'informational'
  } else if (status === 'deleting') {
    return 'negativehigh'
  }
  return 'neutral'
}

export const useInstance = () => {
  return {
    isInstanceDeletedByNameFn,
    isInstanceDeletedFn,
    isInstanceReadyByNameFn,
    isInstanceReadyFn,
    isActiveInstanceReady,
    instanceClaims: instanceScopes,
    instances,
    instanceLocations,
    activeInstance,
    poolOptions,
    removeInstance,
    addInstance,
    getInstances,
    istioEnabled,
    isPaidInstance,
    getTranslatedInstanceDetails,
    translatedInstanceDetails,
    audience,
    instanceReady,
    instanceNames,
    hasInstances,
    setInstances,
    timeOptions,
    durationOptions,
    init,
    deploymentType,
    deploymentTypeLabel,
    getDeploymentType,
    getDeploymentTypeLabel,
    isActiveInstanceServerless,
    getCloudProviderByName,
    getInstanceStatusByName,
    getInstanceStatus,
    getStatusLabel,
    getStatusColor,
    activeInstanceType,
    activeInstanceTypeFunc
  }
}
