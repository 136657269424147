import type { Location, Pool } from '@/api/organizations'
import { getLocationsAndPools as getLocationsAndPoolsApi } from '@/api/organizations'
import type { PulsarState } from './usePulsarState'
import { view, PoolOption, useRbac } from '@/composables/useRbac'
import type { V1alpha1Pool } from '@streamnative/cloud-api-client-typescript'

let lastOrg: string | undefined = undefined
const locations = ref<Location[]>([])
const managedPools = ref<Location[]>([])
const managedProPools = ref<Location[]>([])
const hostedPools = ref<Location[]>([])
// pools that with related pooloption is exists
const pools = ref<Pool[]>([])
// all pools that no matter if exists related pooloption or not
const fullPools = ref<V1alpha1Pool[]>([])

const locationsAndPoolsUpdated = (locationsAndPools: {
  locations: Location[]
  pools: Pool[]
  fullPools: V1alpha1Pool[]
}) => {
  locations.value = locationsAndPools.locations
  pools.value = locationsAndPools.pools
  fullPools.value = locationsAndPools.fullPools
  managedPools.value = locations.value.filter(loc => loc.pool.deploymentType === 'managed')
  managedProPools.value = locations.value.filter(loc => loc.pool.deploymentType === 'managed-pro')
  hostedPools.value = locations.value.filter(loc => loc.pool.deploymentType === 'hosted')
}

export const init = (
  initialState: PulsarState,
  can?: ((action: string, type: any, conditions?: any) => boolean) | true
) => {
  const { organization } = usePulsarState()
  const { abilityUpdating } = useRbac()

  const valueChanged = async ([org, ab]: [string | undefined, boolean | undefined]) => {
    if (!org) {
      locationsAndPoolsUpdated({ locations: [], pools: [], fullPools: [] })
      lastOrg = undefined
      return
    }
    if (ab) {
      return
    }

    if (org !== lastOrg || !ab) {
      if (can && (can === true || can(view, PoolOption))) {
        locationsAndPoolsUpdated(await getLocationsAndPoolsApi(org))
      }
    }
    lastOrg = org
  }

  watch([organization, abilityUpdating], valueChanged)
  return valueChanged([initialState.organization, abilityUpdating.value])
}

export const getLocationsAndPools = async (
  can?: ((action: string, type: any, conditions?: any) => boolean) | true
) => {
  const { mustOrganization } = usePulsarState()
  if (can && (can === true || can(view, PoolOption))) {
    locationsAndPoolsUpdated(await getLocationsAndPoolsApi(mustOrganization()))
  }
}

export const findPool = (poolRef: { name: string; namespace: string } | undefined) => {
  if (!poolRef) {
    return undefined
  }
  return pools.value.find(
    p => p.poolRef.name === poolRef.name && p.poolRef.namespace === poolRef.namespace
  )
}

export const usePools = () => {
  return {
    locations,
    pools,
    fullPools,
    managedPools,
    managedProPools,
    hostedPools,
    init,
    findPool,
    getLocationsAndPools
  }
}
